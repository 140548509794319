<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo centro de custo</span>
    </button>
    <b-table
      :data="allCostCenters"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      "
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="Código"
        width="40"
        numeric
        v-slot="props"
        >{{ props.row.code }}</b-table-column
      >

      <b-table-column
        field="nome"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="customer"
        label="Cliente"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.customer.name }}
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Código">
              <b-input type="number" required v-model="model.code"></b-input>
            </b-field>
            <b-field label="Nome">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Cliente">
              <b-select
                expanded
                placeholder="Selecione um cliente"
                required
                v-model="model.customer_id"
              >
                <option
                  v-for="(item, index) in allCustomers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CostCenter from '../models/cost-center'
import service from '../features/cost-centers/store/service'

export default {
  name: 'CostCenters',
  data() {
    return {
      isCardModalActive: false,
      model: CostCenter.model,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('costCenters', ['allCostCenters']),
    ...mapState('customers', ['allCustomers']),
  },
  methods: {
    ...mapActions('costCenters', ['getAllCostCenters']),
    ...mapActions('customers', ['getAllCustomers']),
    async init() {
      await this.getAllCostCenters()
      await this.getAllCustomers()
    },
    async save() {
      const costCenter = { ...this.model }

      try {
        const save = await service.saveCostCenter(costCenter)

        await this.$success('Centro de Custo')
        await location.reload(true)

        this.model = CostCenter.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o centro de custo?')

        if (result.isConfirmed) {
          await service.deleteCostCenter(id)

          await this.$delete('Centro de Custo')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
